import { Chat } from './reducers';
import { ChatActionTypes } from './types';

export interface AddMessageAction {
    type: ChatActionTypes.ADD_MESSAGE;
    payload: {
        chat: Chat
    };
}

export interface ResetChatAction {
    type: ChatActionTypes.RESET_CHAT;
}

export interface ResetInputMessage {
    type: ChatActionTypes.RESET_INPUT_MESSAGE;
}

export interface SetInputMessage {
    type: ChatActionTypes.SET_INPUT_MESSAGE;
    payload: {
        inputMessage: string;
    }
}

export interface setChatInput {
    type: ChatActionTypes.SET_CHAT_INPUT;
    payload: {
        chatInput: string;
    }
}

export interface setIsFirstResponseAction {
    type: ChatActionTypes.SET_IS_NEW_CONVERSATION;
    payload: {
        isFirstResponse: boolean;
    }
}

export type ChatAction = AddMessageAction | ResetChatAction | setIsFirstResponseAction | ResetInputMessage | SetInputMessage | setChatInput;

export const addMessage = (chat: Chat): AddMessageAction => ({
    type: ChatActionTypes.ADD_MESSAGE,
    payload: {
        chat
    }
});

export const resetChat = (): ResetChatAction => ({
    type: ChatActionTypes.RESET_CHAT
});

export const resetInputMessage = (): ResetInputMessage => ({
    type: ChatActionTypes.RESET_INPUT_MESSAGE
});

export const setInputMessage = (inputMessage: string): SetInputMessage => ({
    type: ChatActionTypes.SET_INPUT_MESSAGE,
    payload: {
        inputMessage
    }
});

export const setIsFirstResponse = (isFirstResponse: boolean): setIsFirstResponseAction => ({
    type: ChatActionTypes.SET_IS_NEW_CONVERSATION,
    payload: {
        isFirstResponse
    }
});

export const setChatInput = (chatInput: string): setChatInput => ({
    type: ChatActionTypes.SET_CHAT_INPUT,
    payload: {
        chatInput
    }
});
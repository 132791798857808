import React, { useState } from 'react';
import ReactEmoji, {Emojione} from 'react-emoji-render';

interface ThumbsFeedbackProps {
  onFeedbackSelected: (feedback: 'thumbs-up' | 'thumbs-down') => void;
}

const ThumbsFeedback: React.FC<ThumbsFeedbackProps> = ({ onFeedbackSelected }) => {
  const [selectedFeedback, setSelectedFeedback] = useState<'thumbs-up' | 'thumbs-down' | null>(null);

  const handleFeedbackClick = (feedback: 'thumbs-up' | 'thumbs-down') => {
    setSelectedFeedback(feedback);
    onFeedbackSelected(feedback);
  };

  return (
    <div>      
      <div style={{ fontSize: '1.6em', float: 'right' }}>
        <span
          onClick={() => handleFeedbackClick('thumbs-up')}
          style={{
            cursor: 'pointer',
            margin: '0.1em',
            opacity: selectedFeedback === 'thumbs-up' ? 1 : 0.5,
          }}
        >
          <Emojione text='👍' />
        </span>
        <span
          onClick={() => handleFeedbackClick('thumbs-down')}
          style={{
            cursor: 'pointer',
            margin: '0.1em',
            opacity: selectedFeedback === 'thumbs-down' ? 1 : 0.5,
          }}
        >
          <Emojione text='👎'/>
        </span>
      </div>
    </div>
  );
};

export default ThumbsFeedback;
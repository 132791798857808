import React, { Component } from 'react';
import { Card } from 'primereact/card';

class UnAuthorized extends Component {
    render() {
        const header = (
            <div style={{ fontSize: '2em', textAlign: 'center' }}>
                <i className="pi pi-exclamation-circle" style={{ color: 'red' }}></i>
            </div>
        );

        return (
            <div className="p-grid p-justify-center">
                <div className="p-col-12 p-md-4 mx-4">
                    <Card title="Not Authorized" subTitle="Access Denied" style={{ textAlign: 'center' }} header={header}>
                        <p>You don't have the required role to access this page.</p>
                        {/* <Link to="/">Go Home</Link> */}
                    </Card>
                </div>
            </div>
        );
    }
}

export default UnAuthorized;

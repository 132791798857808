import React, { Component } from 'react';


import './landingPage.scss';
import { Row } from 'react-bootstrap';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import SignIn from '../layout/SignIn';


interface ComponentProps {

}

type LandingPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

class LandingPage extends Component<LandingPageProps> {



    render() {

        return (
            <div className="sign-in-page d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <div>
                        <div className="header-description">Welcome to Chat GPT application</div>
                        <div className="description">As a Unifi users you can interact and get more</div>
                        <div className="description">information with ChatGPT.</div>
                    </div >
                </div >
                <Row className="justify-content-center">
                    <SignIn signInBtnText="Log In" />
                </Row>
            </div >
        );

    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): LandingPageProps => {
    const { internalUser: auth } = state;

    return {
        ...auth,
        ...ownProps,
    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

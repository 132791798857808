import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { Chat, addMessage, resetChat, setInputMessage, setIsFirstResponse, setChatInput } from '../../store/chat';
import { ChatType } from '../../shared/models/enums/ChatType';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ChatApi } from '../../api/ChatApi';
import { ProcessRequest } from '../../shared/models/ProcessRequest';

interface ComponentProps {
    isFirstResponse  : boolean;
    inputMessage: string;
    chatInput: string;
    showToast: (severity: any, summary: any, detail: any) => void;
}

type ChatInputComponentProps = ComponentProps & typeof mapDispatchToProps;

interface ChatInputState {
    inputMessage: string;
    loading: boolean;
    rows: number;
    chatInput: string;
}

class ChatInputComponent extends Component<ChatInputComponentProps, ChatInputState> {
    private textareaRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: ChatInputComponentProps) {
        super(props);
        this.state = {
            inputMessage: '',
            loading: false,
            rows: 1,
            chatInput: ''
        };
        this.textareaRef = React.createRef();
    }

    componentDidUpdate(prevProps: ChatInputComponentProps) {
        if (this.props.inputMessage !== prevProps.inputMessage) {
            this.setState({ inputMessage: this.props.inputMessage });
            this.setState({ chatInput: this.props.inputMessage });
            if (this.props.inputMessage) {
                setTimeout(() => {
                    this.handleUserInput();
                });
            }
        }
    }

    handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ inputMessage: event.target.value });
        this.setState({ chatInput: event.target.value });
    }

    addErrorMessage = () => {
        const errorChat: Chat = {
            message: 'Something went wrong. Please try again.',
            type: ChatType.error
        }
        this.props.addMessage(errorChat);
    }

    handleUserInput = async () => {
        try {
            this.setState({ loading: true });
            const { inputMessage } = this.state;
            const { isFirstResponse } = this.props;
            const { chatInput } = this.state;

            const chat: Chat = {
                message: inputMessage?.trim() || '',
                type: ChatType.user
            }
            this.props.addMessage(chat);

            const request: ProcessRequest = { message: inputMessage, isFirstResponse: isFirstResponse }
            const response = await ChatApi.auth().ProcessMessage(request);
            if (response) {
                const chat: Chat = {
                    message: response?.message,
                    type: ChatType.system,
                    documentNames: response?.documentNames,
                    pages: response?.pages
                }
                this.props.addMessage(chat);
                this.props.setIsFirstResponse(false);
                if (this.textareaRef.current) {
                    this.textareaRef.current.style.height = 'auto';
                }
                this.props.setChatInput(chatInput);
                this.setState({ inputMessage: '', rows: 1 });
            } else {
                this.addErrorMessage();
            }
        } catch (error) {
            this.addErrorMessage();
        }
        this.setState({ loading: false });
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { loading, inputMessage } = this.state;
        if (event.key === 'Enter' && !event.shiftKey && !loading && inputMessage) {
            event.preventDefault();           
            this.handleUserInput();
        }
    }

    render() {
        const { loading, rows, inputMessage} = this.state;
        return (
            <div className="inputWrapper">
                <div className="chatInput p-d-flex p-jc-between">
                    <span className="p-float-label">
                        {loading &&
                            <div className="loadingOverlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        }
                        <InputTextarea autoResize
                            ref={this.textareaRef}
                            className='chat-input'
                            id="message"
                            value={inputMessage}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyPress}
                            placeholder='Type your message...'
                            rows={rows}
                        />
                    </span>
                    <Button className='mb-0 mr-4' label="Ask" onClick={this.handleUserInput} disabled={!inputMessage?.trim() || loading} />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    addMessage,
    resetChat,
    setIsFirstResponse,
    setInputMessage,
    setChatInput
};

const mapStateToProps = (state: ApplicationState) => ({
    isFirstResponse: state.chat?.isFirstResponse ?? true,
    inputMessage: state.chat?.inputMessage ?? '',
    chatInput: state.chat?.chatInput ?? ''
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInputComponent);

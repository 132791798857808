import BaseApiClient from './BaseApi';

class GraphApi extends BaseApiClient {
    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        super('https://graph.microsoft.com/beta', authenticated);
    }

    public getOrganization(): Promise<any> {
        const requestUrl = this._appendUrlSegment('organization');
        return this._get<any>(requestUrl, ['User.Read']);
    }
}

export default GraphApi;

import { ApplicationState } from '.';

const initialState: ApplicationState = {
    internalUser: {
        userName: '',
        userType: undefined,
        accessTokens: {},
        roles: [],
        requestedPage: undefined,
        isAuthenticated: false,
        authenticating: false,
        msalReady: false,
        idToken: undefined
    },
    chat: {
        chatResponses: [],
        isFirstResponse: true,
        inputMessage: '',
        chatInput: ''
    }
};

export default initialState;

import { appSettings } from '..';
import { ProcessRequest } from '../shared/models/ProcessRequest';
import { FeedbackRecord } from '../shared/models/FeedbackRecord';
import BaseApiClient from './BaseApi';

export class ChatApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { ChatGPTApi } = appSettings;
        super(ChatGPTApi.host + '/api', authenticated);
    }

    public ProcessMessage(request: ProcessRequest): Promise<any> {
        return this._post<any>(this.baseUrl + '/process-message', request, ChatApi.scopes);
    }

    public FetchBlob(blobName: string): Promise<any> {
        return this._getFile(this.baseUrl + '/fetch-blob/' + blobName, ChatApi.scopes);
    }

    public RecordFeedback(feedbackRecord: FeedbackRecord): Promise<any> {
        return this._post<any>(this.baseUrl + '/recordFeedback', feedbackRecord, ChatApi.scopes);
    }
}
/* eslint-disable no-unused-vars */
import { StringDict } from 'msal/lib-commonjs/MsalTypes';

export interface UserState {
    userName: string | undefined;
    userType: UserTypes | undefined;
    authenticating: boolean;
    accessTokens: StringDict | undefined;
    roles: string[];
    requestedPage: string | undefined;
    isAuthenticated: boolean;
    rawToken?: string;
}

export enum UserTypes {
    internal,
    external
}

export enum UserActionTypes {
    INITIALIZE_START = '@@extAuth/INITIALIZE_START',
    INITIALIZE_COMPLETE = '@@extAuth/INIT_COMPLETE',
    SIGNIN_START = '@@extAuth/SIGNIN_START',
    SIGNIN_SUCCESS = '@@extAuth/SIGNIN_SUCCESS',
    SIGNIN_FAIL = '@@extAuth/SIGNIN_FAIL',
    SIGNOUT_START = '@@extAuth/SIGNOUT_START',
    AUTHORIZE_SUCCESS = '@@extAuth/AUTHORIZE_SUCESS',
    AUTHORIZE_FAILURE = '@@extAuth/AUTHORIZE_FAILURE',
    SESSION_UPDATE = '@@extSession/SESSION_UPDATE',
    SESSION_INVALIDATE = '@@extSession/SESSION_INVALIDATE',
    RESET_PASSWORD_SUCCESS = '@@extReset/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_START = '@@extReset/RESET_PASSWORD_START',

    EXT_NAME_SET = '@@extAuth/EXT_NAME_SET',
    EXT_NAME_RESET = '@@extAuth/EXT_NAME_RESET',
    EXT_TYPE_SET = '@@extAuth/EXT_TYPE_SET',
    EXT_TYPE_RESET = '@@extAuth/EXT_TYPE_RESET',
    EXT_ISAUTHENTICATED_SET = '@@extAuth/EXT_ISAUTHENTICATED_SET',
    EXT_ISAUTHENTICATED_RESET = '@@extAuth/EXT_ISAUTHENTICATED_RESET'
}

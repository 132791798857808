import * as React from 'react';

interface AppLoaderStyles {
    root: React.CSSProperties;
}

interface AppLoaderProps {
    styles?: AppLoaderStyles;
}

class AppLoader extends React.Component<AppLoaderProps> {
    public render() {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
}

export default AppLoader;

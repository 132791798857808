// type Action<T> = (item: T) => void;
// type Func<T, TResult> = (item: T) => TResult;

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function retry(action: (...args: any[]) => void, timeoutMs: number, maxRetries: number): any {
    return new Promise<void>((resolve, reject) => {
        try {
            action();
            return resolve();
        } catch (err) {
            if (maxRetries - 1 > 0) {
                return wait(timeoutMs).then(retry.bind(null, action, timeoutMs, maxRetries - 1)).then(resolve).catch(reject);
            }

            return reject(err);
        }
    });
}

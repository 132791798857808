/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import 'whatwg-fetch';
import { StringHelper } from '../helpers';
import { AuthActions } from '../store/auth';
import ApiError from '../shared/models/ApiError';


class BaseApiClient {
    public static defaultScopes = [
        'User.Read',
        'User.ReadBasic.All',
        'email', 'openid', 'profile',
    ];

    public baseUrl: string;
    public authenticated: boolean;
    public isInternal: boolean | undefined;

    constructor(baseUrl: string, authenticated?: boolean, isInternal: boolean = false) {
        this.baseUrl = baseUrl;
        this.authenticated = (authenticated === undefined) ? false : authenticated;
        this.isInternal = (isInternal === undefined) ? false : isInternal;
    }

    protected _get<T>(requestUrl: string, scope: string[], options?: RequestInit) {
        const headers = {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
        };

        const request = new Request(requestUrl, {
            headers,
            ...options,
            method: 'GET'
        });

        return (this.authenticated) ? this._sendAuthenticated<T>(request, scope) : this._send<T>(request);
    }

    protected _getFile(requestUrl: string, scope: string[], options?: RequestInit) {
        // Calling the existing _get method
        return this._get<Blob>(requestUrl, scope, {
            ...options,
            headers: {
                ...options?.headers,
                'Accept': 'application/octet-stream',  // Assuming the file is a binary file
            },
        }).then(blob => {
            // Here, you can handle the received blob object, for example, by creating an ObjectURL
            const url = window.URL.createObjectURL(blob);

            // Perform actions with the URL, e.g., set it to an <a> element's href attribute for downloading
            // ...

            return url;  // Returning the ObjectURL for further use
        });
    }

    protected _post<T>(requestUrl: string, serializableObject: object | string, scope: string[], options?: RequestInit) {
        let body: string;
        let contentType: string;
        if (typeof serializableObject === 'object') {
            contentType = 'application/json';
            body = JSON.stringify(serializableObject);
        } else {
            contentType = 'text/plain';
            body = serializableObject;
        }

        const headers = {
            Accept: 'application/json',
            'Content-type': contentType
        };

        const request = new Request(requestUrl, {
            headers,
            ...options,
            method: 'POST',
            body,
        });

        return (this.authenticated) ? this._sendAuthenticated<T>(request, scope) : this._send<T>(request);
    }

    protected _postFile<T>(requestUrl: string, serializableObject: FormData, scope: string[], options?: RequestInit) {
        const headers = {
            // "Content-Type": "application/x-www-form-urlencoded"
        };

        const request = new Request(requestUrl, {
            headers,
            ...options,
            method: 'POST',
            body: serializableObject,
        });

        return (this.authenticated) ? this._sendAuthenticated<T>(request, scope) : this._send<T>(request);
    }

    protected _patch<T>(requestUrl: string, serializableObject: object | string, scope: string[], options?: RequestInit) {
        let body: string;
        let contentType: string;
        if (typeof serializableObject === 'object') {
            contentType = 'application/json';
            body = JSON.stringify(serializableObject);
        } else {
            contentType = 'text/plain';
            body = serializableObject;
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': contentType,
        };

        const request = new Request(requestUrl, {
            headers,
            ...options,
            method: 'PATCH',
            body,
        });

        return (this.authenticated) ? this._sendAuthenticated<T>(request, scope) : this._send<T>(request);
    }

    protected _put<T>(requestUrl: string, serializableObject: object | string, scope: string[], options?: RequestInit) {
        let body: string;
        let contentType: string;
        if (typeof serializableObject === 'object') {
            contentType = 'application/json';
            body = JSON.stringify(serializableObject);
        } else {
            contentType = 'text/plain';
            body = serializableObject;
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': contentType,
        };

        const request = new Request(requestUrl, {
            headers,
            ...options,
            method: 'PUT',
            body,
        });

        return (this.authenticated) ? this._sendAuthenticated<T>(request, scope) : this._send<T>(request);
    }

    protected _delete(requestUrl: string, scope: string[], options?: RequestInit) {
        const request = new Request(requestUrl, {
            ...options,
            method: 'DELETE',
        });

        return (this.authenticated) ? this._sendAuthenticated(request, scope) : this._send(request);
    }

    protected _send<T>(request: Request, result?: any) {
        const successCodes = [200, 201, 202, 203, 204];
        const authenticated = request.headers.has('Authorization');

        return new Promise<T>((resolve, reject) => {
            fetch(request, {
                credentials: (authenticated) ? 'include' : undefined,
            }).then(
                (response) => {
                    if (successCodes.indexOf(response.status) !== -1) {
                        this._handleResponse<T>(response).then(
                            (data: any) => {
                                if (data && data.hasOwnProperty('value')) {
                                    result = data.hasOwnProperty.call('value').concat(result);
                                } else {
                                    result = data;
                                }

                                const nextLink = (typeof data === 'object' && data && '@odata.nextLink' in data) ? data['@odata.nextLink'] : undefined;
                                if (nextLink) {
                                    const nextRequest = new Request(nextLink, {
                                        headers: request.headers,
                                        method: request.method,
                                    });
                                    this._send<T>(nextRequest, result).then(resolve, reject).catch(reject);
                                } else {
                                    resolve(result as T);
                                }
                            },
                            (error) => {
                                if ('error' in error) {
                                    reject(error.error);
                                } else {
                                    reject(error);
                                }
                            },
                        );
                    } else if (response.status === 401) {
                        reject({
                            code: response.status,
                            name: 'unauthorized',
                            description: 'The user is not authorized to view this resource',
                            message: 'You are not authorized to view this resource.',
                        } as ApiError);
                    } else {
                        this._handleResponse<any>(response).then(
                            (errorObj) => {
                                // eslint-disable-next-line eqeqeq
                                if (errorObj !== undefined && errorObj !== null && errorObj.constructor == Object) {
                                    if ('error' in errorObj) {
                                        reject(errorObj.error);
                                    } else {
                                        reject(errorObj);
                                    }
                                } else {
                                    reject({
                                        code: response.status,
                                        name: response.statusText,
                                        message: 'Uh-oh! We had trouble completing this request. Please try again',
                                    } as ApiError);
                                }
                            },
                            (error) => {
                                reject(error);
                            },
                        ).catch(reject);
                    }
                },
                (error) => {
                    // tslint:disable-next-line:no-console
                    console.error(error);
                    const err: ApiError = {
                        code: 500,
                        name: 'request_failed',
                        description: 'The fetch request failed.',
                        message: 'Well this is embarrassing. Something went wrong but we\'re not sure what. Please try again later.',
                    };
                    reject(err);
                },
            );
        });
    }

    protected _sendAuthenticated<T>(request: Request, scope: string[]) {
        return new Promise<T>((resolve, reject) => {
            this._authenticateRequest(request, scope).then(() => {
                this._send<T>(request).then(
                    (result) => {
                        resolve(result);
                    },
                    (error) => {
                        if (error.hasOwnProperty('code') && error.code === 401) {
                            reject({
                                code: 401,
                                name: 'unauthorized',
                                description: 'The user is not authorized to view this resource',
                                message: 'You are not authorized to view this resource.',
                            } as ApiError);
                        } else {
                            reject(error);
                        }
                    },
                );
            },
                (error: any) => {
                    console.error('Failed to acquire token for request.', error);
                    reject(error);
                });
        });
    }

    /**
     * Appends urlSegment to url
     *
     * @returns The concatenation of url and urlSegment.
     */
    protected _appendSegmentToUrl(url: string, urlSegment: string) {
        const baseUrl = StringHelper.stripTrailingSlash(url.trim());
        urlSegment = StringHelper.stripLeadingSlash(urlSegment.trim());

        return baseUrl + '/' + urlSegment;
    }

    /**
     * Builds an absolute URL
     * from the resource path and
     * the urlSegment.
     *
     * @returns The absolute URL with the appended URL segment.
     */
    protected _appendUrlSegment(urlSegment: string | null) {
        const baseUrl = this.baseUrl;
        if (urlSegment === null) {
            return baseUrl;
        } else {
            return this._appendSegmentToUrl(baseUrl, urlSegment);
        }
    }

    protected _authenticateRequest = (request: Request, scope: string[]) => {
        return AuthActions.acquireToken(scope).then(
            (token: string) => {
                this._setAuthorizationHeader(request, token);
            },
        );
    };

    protected _handleResponse = <T>(response: Response) => {
        const json = ['application/json', 'application/x-javascript', 'text/javascript', 'text/x-javascript', 'text/x-json'];
        const text = ['text/plain', 'text/html', 'text/css', 'text/csv', 'application/xhtml+xml', 'application/xml'];
        let contentType = response.headers.get('Content-Type');
        if (contentType) {
            contentType = contentType.toLowerCase();
            contentType = contentType.split(';')[0];
        }

        return new Promise<T>((resolve, reject) => {
            if (response.status === 204) {
                resolve(null as any);
            } else if (contentType && json.indexOf(contentType) !== -1) {
                this._handleJsonResponse<T>(response, resolve, reject);
            } else if (contentType && text.indexOf(contentType) !== -1) {
                this._handleTextResponse(response, resolve, reject);
            } else {
                this._handleBlobResponse(response, resolve, reject);
            }
        });
    };

    private _setAuthorizationHeader(request: Request, token: string) {
        const headerValue = 'Bearer ' + token;
        if (request.headers.has(HTTP_HEADERS.AUTHORIZATION)) {
            request.headers.set(HTTP_HEADERS.AUTHORIZATION, headerValue);
        } else {
            request.headers.append(HTTP_HEADERS.AUTHORIZATION, headerValue);
        }
    }

    private _handleBlobResponse(response: Response, resolve: any, reject: any) {
        response.blob().then(
            (blob) => {
                resolve(blob);
            },
            (error) => {
                console.error(error);
                const err: ApiError = {
                    code: 500,
                    name: 'read_failed',
                    description: 'Failed to read body to blob.',
                    message: 'Well this is embarrassing. Something went wrong but we\'re not sure what. Please try again later.',
                };
                reject(err);
            },
        );
    }

    private _handleJsonResponse<T>(response: Response, resolve: any, reject: any) {
        response.json().then(
            (json) => {
                resolve(json as T);
            },
            (error) => {
                console.error(error);
                const err: ApiError = {
                    code: 500,
                    name: 'read_failed',
                    description: 'Failed to read body to JSON.',
                    message: 'Well this is embarrassing. Something went wrong but we\'re not sure what. Please try again later.',
                };
                reject(err);
            },
        );
    }

    private _handleTextResponse(response: Response, resolve: any, reject: any) {
        response.text().then(
            (body) => {
                resolve(body);
            },
            (error) => {
                console.error(error);
                const err: ApiError = {
                    code: 500,
                    name: 'read_failed',
                    description: 'Failed to read body to text.',
                    message: 'Well this is embarrassing. Something went wrong but we\'re not sure what. Please try again later.',
                };
                reject(err);
            },
        );
    }

}

class HTTP_HEADERS {
    public static AUTHORIZATION: string = 'Authorization';
}

export default BaseApiClient;

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as React from 'react';
import { Provider } from 'react-redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { retry } from './utilities';
import { AppConfiguration, getAppSettings } from './appsettings';
import './index.css';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';
import { persistor, store } from './store';
import AppRoutes from './routes';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import AppLoader from './components/layout/AppLoader';

export let appSettings: AppConfiguration;
export let appInsights: ApplicationInsights;

const handleSettingsException = (error?: any) => {
  console.error(error);
};
const container = document.getElementById('root')!;
const root = createRoot(container);


const render = (element: React.FunctionComponentElement<any> | Array<React.FunctionComponentElement<any>>) => {
  root.render(element);
};

render(<AppLoader />);

getAppSettings()
  .then((settings) => {
    appSettings = settings;
    retry(() => {
      // registerFlags(store);
    }, 1000, 5)
      .catch((reason: any) => {
        if (appInsights) {
          appInsights.trackException({
            exception: reason,
          });
        } else {
          console.error(reason);
        }
      });

    render(
      <Provider store={store}>
        <PersistGate loading={<p>Loading...</p>} persistor={persistor}>
          <App>
            <React.Fragment>
              <AppRoutes />
            </React.Fragment>
          </App>
        </PersistGate>
      </Provider>
    );
  })
  .catch(handleSettingsException);
import UserHelper from '../../helpers/UserHelper';
import { store } from '../../store';

interface AuthData {
    checkTokenExpiration: () => Promise<boolean>;
}

const useAuth = (): AuthData => {
    const state = store.getState();
    const checkTokenExpiration = async (): Promise<boolean> => {
        const isInternal = UserHelper.isInternalUserValid(state);

        return isInternal;
    };

    return { checkTokenExpiration };
};

export default useAuth;

import { appSettings } from '..';
import BaseApiClient from './BaseApi';

export class UploadApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { ChatGPTApi } = appSettings;
        super(ChatGPTApi.host + '/api', authenticated);
    }

    public uploadFile(formData: any): Promise<any> {
        return this._postFile<any>(this.baseUrl + '/upload', formData, UploadApi.scopes);
    }
}
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FileUploadComponent from '../FileUpload/FileUploadComponent';
import { resetChat, resetInputMessage } from '../../store/chat';
import { connect } from 'react-redux';
import './SidebarComponent.scss';

interface ComponentProps {
    isUploadDialogVisible: boolean;
    closeDialog: () => void;
    showToast: (severity: any, summary: any, detail: any) => void;
    setUploadDialogVisibility: (visible: boolean) => void;
}

type SidebarProps = ComponentProps & typeof mapDispatchToProps;

class SidebarComponent extends Component<SidebarProps> {

    renderUploadDialog() {
        const { isUploadDialogVisible, closeDialog, showToast } = this.props;
        return (
            <Dialog header="Upload File"
                visible={isUploadDialogVisible}
                style={{ width: '50vw' }}
                onHide={closeDialog}>
                <div className="card">
                    <FileUploadComponent onCloseDialog={closeDialog} showToast={showToast} />
                </div>
            </Dialog>
        )
    }

    clearChat = () => {
        this.props.resetInputMessage();
        this.props.resetChat();
    }

    render() {
        // const { setUploadDialogVisibility } = this.props;
        return (
            <React.Fragment>
                <div className="left-column">
                    <div className="top-section">
                        <Button className='my-2' label="Clear Chat" outlined icon="pi pi-trash"
                            severity="info" onClick={this.clearChat} size="small" />
                    </div>
                    {/* <div className="upload-area">
                        {this.renderUploadDialog()}
                        <Button label="Upload File"
                            icon="pi pi-upload" outlined
                            severity="info"
                            onClick={() => setUploadDialogVisibility(true)}
                            size="small" />
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    resetChat,
    resetInputMessage
};


export default connect(undefined, mapDispatchToProps)(SidebarComponent);

import { ChatType } from '../../shared/models/enums/ChatType';
import { ChatAction } from './actions';
import { ChatActionTypes } from './types';

export interface ChatState {
    chatResponses: Array<Chat>;
    isFirstResponse: boolean;
    inputMessage: string;
    chatInput: string;
}

export interface Chat {
    type: ChatType,
    message: string,
    documentNames?: Array<string>;
    pages?: Array<string>;
    isFirstResponse?: boolean;
}

const initialState: ChatState = {
    chatResponses: [],
    isFirstResponse: true,
    inputMessage: '',
    chatInput: ''
};

export const chatReducer = (state = initialState, action: ChatAction): ChatState => {
    switch (action.type) {
        case ChatActionTypes.ADD_MESSAGE: {
            return {
                ...state,
                chatResponses: [...state.chatResponses, {
                    type: action.payload.chat?.type,
                    message: action.payload.chat?.message,
                    documentNames: action.payload.chat?.documentNames,
                    pages: action.payload.chat?.pages,
                    isFirstResponse: state.isFirstResponse
                }]
            };
        }
        case ChatActionTypes.RESET_CHAT: {
            return {
                ...state,
                chatResponses: []
            };
        }
        case ChatActionTypes.SET_IS_NEW_CONVERSATION: {
            return {
                ...state,
                isFirstResponse: action.payload?.isFirstResponse
            }
        }
        case ChatActionTypes.RESET_INPUT_MESSAGE: {
            return {
                ...state,
                inputMessage: ''
            }
        }
        case ChatActionTypes.SET_INPUT_MESSAGE: {
            return {
                ...state,
                inputMessage: action.payload?.inputMessage
            }
        }
        case ChatActionTypes.SET_CHAT_INPUT: {
            return {
                ...state,
                chatInput: action.payload?.chatInput
            }
        }
        default:
            return state;
    }
};

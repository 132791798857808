import React, { Component } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Card } from 'react-bootstrap';
import './pdf-viewer.scss';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

export interface ComponentProps {
    blobUrl: string;
    loading: boolean;
    onClose?: () => void;
    fileName: string;
}

export class PdfViewer extends Component<ComponentProps> {

    state = {
        numPages: 0,
        loading: false
    };

    constructor(props: ComponentProps) {
        super(props);

        pdfjs.GlobalWorkerOptions.workerSrc = './scripts/pdf.worker.min.js';
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.state = {
            numPages: 0,
            loading: props.loading
        };
    }

    onDocumentLoadSuccess({ numPages }: any) {
        this.setState({ numPages });
    }

    onClose(event: any) {
        event.preventDefault();
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    downloadPDF(pdfUrl: string) {
        const downloadLink = document.createElement('a');
        const fileName = this.props.fileName;

        downloadLink.href = pdfUrl;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink?.parentNode?.removeChild(downloadLink);
    }

    render() {
        return (
            <React.Fragment>
                {(!this.props.loading && this.props.blobUrl) &&
                    <Document className='document-section' file={this.props.blobUrl} onLoadSuccess={this.onDocumentLoadSuccess} loading={this.props.loading ? 'please wait...' : ''}>
                    {Array.from(new Array(this.state.numPages), (_: any, index: number) => (
                        <Card key={`card_${index + 1}`} className={`${(index + 1 === this.state.numPages) ? '' : 'mb-4'} card-page`}>
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        </Card>
                    ))}
                </Document>}
                {this.props.loading &&
                    <div className='row'>
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                        <Skeleton className='col-12 document-section my-3' />
                    </div>}
                {(!this.props.loading && !this.props.blobUrl) && <h4 className='mt-4'>File Data not found</h4>}
                <div className='row pdf-viewer-footer-row'>
                    <div className='col-12 flex justify-content-center'>
                        <Button size='small' label='Close' onClick={(event) => this.onClose(event)} className='mr-4' />
                        {(!this.props.loading && this.props.blobUrl) &&
                            <Button size='small' label="Download" onClick={() => this.downloadPDF(this.props.blobUrl)} />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

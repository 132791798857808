import React, { Component } from 'react';
import { Chat } from '../../store/chat';
import { ChatApi } from '../../api/ChatApi';
import { Dialog } from 'primereact/dialog';
import { PdfViewer } from '../../shared/components/pdf-viewer/pdf-viewer';

interface Props {
    chat: Chat;
}

interface State {
    blobUrl: string;
    showPdfViewerDialog: boolean;
    docName: string;
    pdfViewerFetching: boolean;
}

class PageNumberComponent extends Component<Props, State> {

    state: State = {
        blobUrl: '',
        showPdfViewerDialog: false,
        docName: '',
        pdfViewerFetching: false
    };

    fetchBlob = async (blobName: string) => {
        try {
            this.setState({ pdfViewerFetching: true, showPdfViewerDialog: true, docName: blobName });

            // Fetch blob data from your Flask API
            const blobUrl = await ChatApi.auth().FetchBlob(blobName);

            // Update the state with the new blob URL
            this.setState({ blobUrl, pdfViewerFetching: false });

        } catch (error) {
            console.error('Failed to fetch blob:', error);
            this.setState({ blobUrl: '', pdfViewerFetching: false });
        }
    }

    renderPdfViewer() {
        const { showPdfViewerDialog, docName, blobUrl, pdfViewerFetching } = this.state;
        return (
            <Dialog header={docName} className='rounded text-center pdf-viewer-dialog'
                visible={showPdfViewerDialog}
                onHide={() => this.setState({ showPdfViewerDialog: false })}>
                <PdfViewer blobUrl={blobUrl} loading={pdfViewerFetching} fileName={docName}
                    onClose={() => this.setState({ showPdfViewerDialog: false })} />
            </Dialog>
        );
    }

    render() {
        const { chat } = this.props;
        return (
            <div>
                {this.renderPdfViewer()}
                {chat.documentNames?.map((doc) => (
                    <div key={doc + 'div'} className="document-container">
                        <strong>In Document(s): </strong>
                        <a
                            key={doc}
                            href={this.state.blobUrl || '#'}
                            onClick={(e) => {
                                e.preventDefault();
                                this.fetchBlob(doc);
                            }}
                            download={`${doc}.pdf`}
                            className="document-link"
                        >
                            {doc}
                        </a>
                        <span className="ml-2">
                            <strong>
                                {chat.pages && chat.pages?.length > 0 && (
                                    <>
                                        {"From Pages "}
                                        {chat.pages}
                                    </>
                                )}
                            </strong>

                        </span>
                    </div>
                ))}
            </div>
        );
    }
}

export default PageNumberComponent;

import React, { Component } from 'react';
import { Card } from 'primereact/card';
import './SuggestionsComponent.scss';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { setInputMessage } from '../../store/chat';

interface ComponentProps {
}

type SuggestionsComponentProps = ComponentProps & typeof mapDispatchToProps;

class SuggestionsComponent extends Component<SuggestionsComponentProps> {
    handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        e.currentTarget.style.backgroundColor = "#fbf0f0";
        e.currentTarget.style.cursor = "pointer"
    }

    handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        e.currentTarget.style.backgroundColor = "";
    }

    handleClick = (message: string) => {
        this.props.setInputMessage(message);
    }

    render() {
        const suggestionCards = [
            { title: "PTO Policy", message: "Describe the PTO Policy in detail" },
            { title: "Medical Plans", message: "Provide full details of all Gold, silver and Bronze Medical Plans in Tabular format." },
            { title: "Travel time policy", message: "Provide details of Travel time policy" }
        ];

        return (
            <React.Fragment>
                <div className='d-flex-title'>
                    <h5 className='suggestion-title'>
                        <span className='ml-1'>Handful of tips to start with...</span>
                        <i className="pi pi-at" style={{ color: '#708090' }}></i>
                    </h5>
                </div>
                <div className="d-flex">
                    {suggestionCards.map((card, index) => (
                        <Card
                            key={card.title}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onClick={() => this.handleClick(card.message)}
                            header={<div className="card-title">{card.title}</div>}
                            className="suggestion-card"
                        >
                            <span className="card-message">{card.message}</span>
                        </Card>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    setInputMessage
};

const mapStateToProps = (state: ApplicationState) => ({
    inputMessage: state.chat?.inputMessage ?? '',
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionsComponent);

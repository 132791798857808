/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route, Routes,
} from 'react-router-dom';
import routePaths from './shared/constants/routePaths';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import PrivateRoute from './components/login/useTokenExpiration';
import { AuthActions } from './store/auth';
import { Header } from './components/layout';
import { UserHelper } from './helpers';
import InteractiveWorkspace from './components/InteractiveWorkspace/InteractiveWorkspace';
import LandingPage from './components/MainLanding/landingPage';
import UnAuthorized from './components/UnAuthorized/UnAuthorized';

interface ComponentProps {
  idToken?: any;
  applicationState?: ApplicationState;
}

type LayoutProps =
  ComponentProps
  & typeof mapDispatchToProps;

class AppRoutes extends Component<React.PropsWithChildren<LayoutProps>> {

  public renderLayout(element: JSX.Element) {
    const { applicationState } = this.props;
    return (
      <React.Fragment>
        <PrivateRoute>
          <Header username={applicationState?.internalUser?.userName} name={applicationState?.internalUser?.idToken?.name} />
          {element}
        </PrivateRoute>
      </React.Fragment>
    );
  }

  renderSign(): JSX.Element {
    return (
      <React.Fragment>
        <Header />
        <LandingPage />
      </React.Fragment>
    );
  }

  redirectInternalUserPageBasedAuth(element: JSX.Element, pageName: string): JSX.Element {
    const { applicationState } = this.props;
    if (applicationState && UserHelper.isInternalUserValid(applicationState)) {
      if (UserHelper.isUserAuthorized(applicationState, pageName)) {
        return this.renderLayout(element);
      } else {
        return <Navigate to={routePaths.Unauthorized} />;
      }
    } else {
      return <Navigate to={routePaths.root} />;
    }
  }

  render() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePaths.root} element={this.renderSign()} />
        <Route path={routePaths.signIn} element={this.renderSign()} />
        <Route path={routePaths.Unauthorized} element={this.renderLayout(<UnAuthorized />)} />
        <Route path={routePaths.signInCallback} element={<Navigate to={routePaths.signIn} />} />
        <Route path={routePaths.interactiveWorkspace} element={this.redirectInternalUserPageBasedAuth(<InteractiveWorkspace />, routePaths.interactiveWorkspace)} />
      </Routes>
    </BrowserRouter>
  );
}
}

const mapStateToProps = (state: ApplicationState, ownProps: ComponentProps) => {
  const { idToken } = state.internalUser;

  return {
    ...ownProps,
    idToken,
    applicationState: state,

  };
};

const mapDispatchToProps = {
  internalSignOut: AuthActions.signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes as any);
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// Constants for hardcoded strings and delimiters
const TEXT_LAST_KEY = 'text-last';
const TABLE_START = '| ';
const TABLE_ROW_DELIMITER = '|';
const TABLE_HEADER_DELIMITER = '| ---';
const NEW_LINE = '\n';

const generateMixedContent = (data: string) => {
    const lines = data.split(NEW_LINE);
    let isTable = false;
    let tableData: string[][] = [];
    let headers: string[] = [];
    let output: JSX.Element[] = [];
    let textContent = '';

    lines.forEach((line, index) => {
        const trimmedLine = line.trim();

        if (trimmedLine.startsWith(TABLE_START)) {
            if (isTable) {
                const row = trimmedLine.split(TABLE_ROW_DELIMITER).filter(col => col.trim().length > 0);
                if (row.length > 0 && !trimmedLine.startsWith(TABLE_HEADER_DELIMITER)) {
                    tableData.push(row);
                }
            } else {
                headers = trimmedLine.split(TABLE_ROW_DELIMITER).filter(col => col.trim().length > 0);
                isTable = true;
            }
        } else {
            if (trimmedLine) {
                textContent += line + NEW_LINE;
            }
        }
    });

    if (textContent.trim()) {
        output.push(
            <p className='messageContent' key={`${TEXT_LAST_KEY}-${output.length}`}>{textContent}</p>
        );
    }

    if (isTable) {
        isTable = false;
        const tableDataObjects = tableData.map((row) => {
            const rowObj: { [key: string]: string } = {};
            row.forEach((cell, cellIndex) => {
                rowObj[headers[cellIndex]?.trim()] = cell;
            });
            return rowObj;
        });

        const dynamicColumns = headers.map((header, index) => (
            <Column key={`col-${index}-${output.length}`} field={header.trim()} header={header.trim()} />
        ));

        output.push(
            <DataTable key={`table-${output.length}`} value={tableDataObjects} size='small' showGridlines>
                {dynamicColumns}
            </DataTable>
        );

        tableData = [];
        headers = [];
    }

    return (
        <div className='row' key={`row-${output.length}`}>
            <div className='col-12 py-0'>
                <>{output}</>
            </div>
        </div>
    )
};

export default generateMixedContent;

import { Component } from 'react';
import { FileUpload as FileUploadPrime } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { UploadApi } from '../../api/UploadApi';
import { connect } from 'react-redux';
import { setIsFirstResponse } from '../../store/chat';

interface ComponentProps {
    onCloseDialog: VoidFunction;
    showToast: (severity: any, summary: any, detail: any) => void;
}

type FileUploadProps = ComponentProps & typeof mapDispatchToProps;

interface State {
    uploadLoading: boolean;
}

class FileUploadComponent extends Component<FileUploadProps, State> {
    state: State = {
        uploadLoading: false
    };

    handleFileUpload = async (event: { files: File[] }) => {
        this.setState({ uploadLoading: true });
        try {
            const formData = new FormData();
            for (const file of event.files) {
                formData.append('file', file);
            }

            UploadApi.auth().uploadFile(formData).then((response) => {
                if (response) {
                    console.log('File uploaded successfully.');
                    this.props.showToast('success', 'Success', 'File uploaded successfully.');
                    this.props.onCloseDialog();
                } else {
                    console.log('File upload failed.');
                    this.props.showToast('error', 'Error', 'File upload failed!');
                }
                this.setState({ uploadLoading: false });
            }).catch(error => {
                console.error(error);
                this.props.showToast('error', 'Error', 'An error occurred!');
                this.setState({ uploadLoading: false });
            });
        } catch (error) {
            console.log(error);
            this.props.showToast('error', 'Error', 'An error occurred!');
            this.setState({ uploadLoading: false });
        }
    }

    render() {
        return (
            <div className="fileUploadComponent">
                <>
                    <FileUploadPrime
                        name="demo"
                        accept=".pdf"
                        customUpload={true}
                        uploadHandler={this.handleFileUpload}
                    />
                    {this.state.uploadLoading && <ProgressBar mode="indeterminate" />}
                </>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setIsFirstResponse
};

export default connect(undefined, mapDispatchToProps)(FileUploadComponent);

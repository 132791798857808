import { appSettings } from '..';
import routePaths from '../shared/constants/routePaths';
import { ApplicationState } from '../store';
import TokenHelper from './TokenHelper';

class UserHelper {


    public static isInternal(state: ApplicationState, pageName: string) {
        return UserHelper.isInternalUserValid(state);
    }

    public static isInternalUserValid(state: ApplicationState): boolean {
        const { internalUser } = state;
        return internalUser?.isAuthenticated && internalUser?.idToken
            && TokenHelper.isAuthenticated(internalUser?.idToken);
    }

    public static isUserAuthorized(state: ApplicationState, pageName: string) {
        let isUserAuthorized = false;
        switch (pageName) {
            case routePaths.interactiveWorkspace:
                isUserAuthorized = state.internalUser?.roles?.includes(appSettings.role.chatGPTMemberRole) ?? false;
                break;

            default:
                break;
        }
        return isUserAuthorized;
    }
}

export default UserHelper;

import { Component } from 'react';
import './ChatComponent.scss';
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';
import { connect } from 'react-redux';
import { Chat, resetChat, setChatInput } from '../../store/chat';
import { ApplicationState } from '../../store';
import generateMixedContent from '../../shared/components/table-from-response/table-from-response';
import PageNumberComponent from '../PageNumber/PageNumberComponent';
import { ChatType } from '../../shared/models/enums/ChatType';
import ChatInputComponent from '../ChatInputComponent/ChatInputComponent';
import SuggestionsComponent from '../Suggestions/SuggestionsComponent';
import ThumbsFeedback from '../Feedback/FeedbackComponent';
import { FeedbackRecord } from '../../shared/models/FeedbackRecord';
import { ChatApi } from '../../api/ChatApi';


interface ComponentProps {
    chatResponses: Chat[];
    chatInput: string;
    showToast: (severity: any, summary: any, detail: any) => void;
}

type ChatComponentProps = ComponentProps & typeof mapDispatchToProps;

interface State {    
}

class ChatComponent extends Component<ChatComponentProps, State> {

    private endOfChat: HTMLDivElement | null = null;
    state: State = {       
    };

    componentDidUpdate(prevProps: ChatComponentProps) {
        if (prevProps.chatResponses.length !== this.props.chatResponses.length) {
            this.endOfChat?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        const { chatResponses } = this.props;

        const handleFeedback = async (feedback: 'thumbs-up' | 'thumbs-down') => {
            console.log(`Selected feedback: ${feedback}`);
           
            try {                         
                var inputMessage = this.props.chatInput;
                console.log('1:', inputMessage)
                var receivedFeedback = '';
                if(feedback === 'thumbs-up')
                    receivedFeedback = 'Yes'
                 else
                    receivedFeedback = 'No' 
    
                const request: FeedbackRecord = { ques: inputMessage, feedback: receivedFeedback }
                const response = await ChatApi.auth().RecordFeedback(request);
                if (response) {
                   console.log(response);
                }
            } catch (error) {
               
            }   
          };          


        return (
            <div className="chatComponent p-d-flex p-flex-column">
                <div className="chatContent">
                    {(!chatResponses || chatResponses.length === 0) ? (
                        <SuggestionsComponent />
                    ) : (chatResponses.map((chat, index) => (
                        <div key={index} className={`row ${chat.type === ChatType.user ? 'userMessage' : 'systemResponse'}`}>
                            {(chat.type === ChatType.system || chat.type === ChatType.error) && (
                                <div className="col-1">
                                    <Avatar icon='pi pi-android' shape="circle" size="large" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} />
                                </div>
                            )}
                            <div className="col pt-0">
                                <div className={`col messageContent py-0 ${chat.type === ChatType.user ? 'justify-content-end' : 'justify-content-start'}`}>
                                    {generateMixedContent(chat.message)}
                                </div>
                                {chat.type === ChatType.system && (<Divider />)}
                                <PageNumberComponent chat={chat} />
                            </div>
                            {chat.type === ChatType.user && (
                                <div className="col-1">
                                    <Avatar icon='pi pi-user' shape="circle" size="large" />
                                </div>
                            )}

                           {chat.type === ChatType.system && 
                                ( <ThumbsFeedback onFeedbackSelected={handleFeedback} />)}
                        </div>
                       
                        
                    )))}
                </div>
                <div ref={(el) => { this.endOfChat = el; }} />
                <ChatInputComponent
                    showToast={this.props.showToast}
                />
            </div>
        );
    }
}

const mapDispatchToProps = {
    resetChat
};

const mapStateToProps = (state: ApplicationState) => ({
    chatResponses: state.chat?.chatResponses ?? [],
    chatInput: state.chat?.chatInput ?? ''
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
import React, { Component, RefObject } from 'react';
import './InteractiveWorkspace.scss';
import ChatComponent from '../chat/ChatComponent';
import { Toast } from 'primereact/toast';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

interface ComponentProps { }

type FileUploadProps = ComponentProps;

interface State {
    isUploadDialogVisible: boolean;
}

class InteractiveWorkspace extends Component<FileUploadProps, State> {
    toast: RefObject<Toast> = React.createRef();
    state: State = {
        isUploadDialogVisible: false
    };

    closeDialog = () => {
        this.setState({ isUploadDialogVisible: false });
    }

    showToast = (severity: any, summary: any, detail: any) => {
        this.toast?.current?.show({ severity, summary, detail, life: 3000 });
    }

    setUploadDialogVisibility = (visible: boolean) => {
        this.setState({ isUploadDialogVisible: visible });
    }

    render() {
        return (
            <div className="mt-1">
                <Toast ref={this.toast} />
                <SidebarComponent
                    isUploadDialogVisible={this.state.isUploadDialogVisible}
                    closeDialog={this.closeDialog}
                    showToast={this.showToast}
                    setUploadDialogVisibility={this.setUploadDialogVisibility}
                />
                <div className="right-column">
                    <div className="response-area">
                        <div className="response-messages">
                            <div className="new-chat-section">
                                <ChatComponent showToast={this.showToast} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InteractiveWorkspace;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import routePaths from '../../shared/constants/routePaths';

interface PrivateRouteProps {
    children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const navigate = useNavigate();
    const { checkTokenExpiration } = useAuth();

    useEffect(() => {
        const checkAuth = async () => {
            const isTokenValid = await checkTokenExpiration();
            if (!isTokenValid) {
                navigate(routePaths.signIn);
            }
        };

        checkAuth();
    }, [navigate, checkTokenExpiration]);

    return <>{children}</>;
};

export default PrivateRoute;

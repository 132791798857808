import moment from 'moment';

class DateHelper {
    public static toUTCTimestamp = (input: Date) => {
        const seconds = input.getTime();
        const output = Math.floor(seconds / 1000);

        return output;
    };

    public static fromUTCTimestamp = (input: number | string) => {
        let output: Date;
        if (typeof input === 'string') {
            output = new Date(input);
        } else {
            const seconds = input * 1000;
            output = new Date(seconds);
        }

        return output;
    };

    public static fromUTCDateTimeToLocalDate(dateString: string, format: string) {
        const utcDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss').utc(true);
        return moment.utc(utcDate).local().format(format);
    }

    public static formatDate(value: Date) {
        if(!value) {
            return '';
        }
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }
}

export default DateHelper;

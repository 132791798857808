/* eslint-disable no-prototype-builtins */
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { Row } from 'react-bootstrap';
import { UserHelper } from '../../helpers';
import { ApplicationState } from '../../store';
import { AuthActions } from '../../store/auth';
import routePaths from '../../shared/constants/routePaths';


interface ComponentProps {
    authenticating?: boolean;
    idToken?: any;
    isAuthenticated?: boolean;
    signInBtnText: string;
    applicationState?: ApplicationState;
}

type SignInPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

interface ComponentState {
    showSignInDialog: boolean;
}

class SignInPage extends React.Component<SignInPageProps, ComponentState> {
    constructor(props: Readonly<SignInPageProps>) {
        super(props);

        this.state = {
            showSignInDialog: false,
        };
    }

    public render() {
        const { authenticating, applicationState } = this.props;
        let isMsalFrame = false;
        if (window.frames && window.frames.frameElement && window.frames.frameElement.id.indexOf('msalRenewFrame') !== -1) {
            isMsalFrame = true;
        }

        if (isMsalFrame) {
            return null;
        } else if (authenticating) {
            return <div>Signing in...</div>;
        }

        if (applicationState && UserHelper.isInternalUserValid(applicationState)) {
            return <Navigate to={routePaths.interactiveWorkspace} />;
        }

        return (
            <>
                <React.Fragment>
                    <Row className="justify-content-center">
                        <button className="col-12 button-option shadow-lg rounded" onClick={this.onContinueClick} >{this.props?.signInBtnText}</button>
                    </Row>
                </React.Fragment>
            </>
        );
    }

    private onContinueClick = () => {
        const { idToken } = this.props;
        if (idToken && idToken.hasOwnProperty('preferred_username') && idToken.preferred_username) {
            const username: string = idToken.preferred_username;
            this.props.signIn(username, true);
        } else {
            this.props.signIn();
        }
    };
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser } = state;
    return {
        applicationState: state,
        authenticating: internalUser.authenticating,
        idToken: internalUser.idToken,
        isAuthenticated: internalUser.isAuthenticated,
        requestedPage: internalUser.requestedPage
    };
};

const mapDispatchToProps = {
    initMsal: AuthActions.initMsalAgent,
    signIn: AuthActions.signIn,
    requestedPageSet: AuthActions.requestedPageSet
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage as any);
